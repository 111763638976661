.Machine {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}

.content {
  display: flex;
  gap: 24px;
}

.main {
  flex: 1;
  max-width: calc(100% - 240px);
}
