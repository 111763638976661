@import "/src/styles";

.root {
  align-items: center;
  background: linear-gradient(90deg, #4c83c3 0%, #609ee1 100%);
  display: flex;
  max-width: 100vw;
  padding-left: 32px;
  padding-right: 32px;
}

.company {
  align-items: center;
  border-left: 1px solid #c9d2d6;
  color: #ffffff;
  display: none;
  font-size: 15px;
  height: 28px;
  line-height: 28px;
  margin-left: 32px;
  padding-left: 32px;

  .company_name {
    margin-left: 8px;
  }
}

.avatar {
  cursor: pointer;
  margin-left: 26px;
}

.mobileMenu {
  margin-left: 15px;
  width: 24px;
}

.mobileMenuDrawer {
  :global {
    .ant-drawer-body {
      background-color: #f2f4f5;
      overflow: hidden;
      padding: 0;
    }
  }
}

.userPopover {
  position: fixed;
  z-index: 10000;

  &:global(.ant-popover-placement-bottom),
  &:global(.ant-popover-placement-bottomLeft),
  &:global(.ant-popover-placement-bottomRight) {
    padding-top: 5px;
  }

  :global {
    .ant-popover-inner-content {
      background: #ffffff;
      border: 1px solid #c9d2d6;
      border-radius: 4px;
      box-shadow: 0 4px 16px rgba(45, 52, 54, 0.16);
      padding: 0;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

@include for-tablet-portrait-up {
  .company {
    display: flex;
  }
}

@include for-desktop-up {
  .mobileMenu {
    display: none;
  }
}
