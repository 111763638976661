.files {
  li {
    margin-bottom: 8px;
  }
}

.link {
  color: #4c83c3;
  composes: body from "/src/index.css";
  margin-left: 8px;

  &:hover {
    color: #4c83c3;
    opacity: 0.75;
  }
}
