.main {
  flex: 0 1 580px;
}

.Type {
  border-bottom: 1px solid #dfe6e9;
  color: #2d3436;
  padding-bottom: 12px;

  & + & {
    margin-top: 24px;
  }

  .imgContainer {
    margin-left: 83px;
    position: relative;

    :global(.InputNumber) {
      position: relative;
      width: 48px;
    }
  }
}
