.Diameter {
  border-bottom: 1px solid #dfe6e9;
  color: #2d3436;
  display: flex;
  gap: 18px;

  & + & {
    margin-top: 24px;
  }

  .diameter {
    flex: 0 0 226px;
  }

  .deepenings {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 24px;
  }

  .drillImgContainer {
    margin-left: 83px;
    position: relative;
  }
}

.Deepening {
  border-bottom: 1px solid #dfe6e9;
  padding-bottom: 16px;

  & + & {
    margin-top: 12px;
  }
}

.hovered {
  background-color: #f2f4f5;
}
