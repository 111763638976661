.ServiceDetails {
  padding-top: 32px;

  & > div {
    margin-bottom: 48px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
