@import "/src/styles";

.BillingsPage {
  padding: 33px 32px 86px 32px;
}

.currentPackage {
  max-width: 100%;
  width: 400px;
}

@include for-desktop-up {
  .BillingsPage {
    padding: 33px 86px 86px 32px;
  }
}
