$spaceamounts: (
  0,
  1,
  2,
  4,
  5,
  6,
  8,
  9,
  10,
  12,
  15,
  16,
  20,
  24,
  25,
  26,
  30,
  32,
  35,
  40,
  45,
  48,
  50,
  75,
  100
);
$sides: (
  "": "all",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);

@each $space in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix == "", "", -#{$value});
    .m#{$prefix}#{$space} {
      margin#{$property}: #{$space}px;
    }
    .p#{$prefix}#{$space} {
      padding#{$property}: #{$space}px;
    }
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

///
@mixin for-small-phone-only {
  @media (max-width: 340px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.hide-mobile {
  @include for-desktop-down {
    display: none !important;
  }
}

.show-mobile {
  @include for-desktop-down {
    display: block !important;
  }
}

.hide-phone {
  @include for-tablet-portrait-down {
    display: none;
  }
}

.show-phone {
  @include for-tablet-portrait-down {
    display: block !important;
  }
}
