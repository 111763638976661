@import "/src/styles";

.SubscriptionPage {
  padding: 32px 20px 86px 20px;
}

.main_layout {
  display: flex;
  gap: 100px;
}

.plans {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.plan {
  border: 1px solid #dfe6e9;
  border-radius: 4px;
  height: 100%;
  max-width: calc(100vw - 90px);
  padding: 32px;
  text-align: center;
  width: 320px;
  word-break: break-word;

  li {
    display: grid;
    grid-template-columns: 35px 1fr;
    margin-bottom: 8px;
    text-align: left;

    &::before {
      content: url("img/check.svg");
      display: block;
      height: 16px;
      margin-right: 4px;
      width: 16px;
    }
  }
}

@include for-desktop-up {
  .SubscriptionPage {
    padding: 33px 86px 86px 32px;
  }
}
