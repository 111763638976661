@import "/src/styles";

.header {
  align-items: flex-start;
  display: flex;

  :global(.Button) {
    align-items: center;
    display: flex;
  }
}

.Element__min {
  align-items: flex-start;
  border-bottom: 1px solid #dfe6e9;
  display: flex;
  padding: 12px 0;

  & > div {
    flex: 1;

    &:last-of-type {
      flex: 0 0 auto;
    }
  }
}

.Element {
  border-bottom: 1px solid #dfe6e9;
  padding: 24px 0 16px;
}

.Element_main {
  column-gap: 16px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 96px 200px 1fr 1fr 1fr;
  row-gap: 15px;
  word-break: break-word;
}

.Element_footer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
}

@include for-tablet-landscape-up {
  .Element_main {
    display: grid;
  }
}
