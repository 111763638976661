@import "/src/styles";

$grid_layout_sm: 32px 112px 0.9fr 1fr 1fr 1fr;
$grid_layout_lg: 32px 112px 200px 242px 242px 242px;

.Element {
  background-color: #ffffff;
  border-bottom: 1px solid #dfe6e9;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;

  &:first-of-type {
    position: relative;
    z-index: 1;
  }
}

.Element__min {
  align-items: flex-start;
  border-bottom: 1px solid #dfe6e9;
  display: flex;
  padding: 12px 0;
  word-break: break-word;

  & > div {
    flex: 1;

    &:last-of-type,
    &:first-of-type {
      flex: 0 0 auto;
    }
  }
}

.main {
  align-items: start;
  display: grid;
  grid-template-columns: $grid_layout_sm;
  padding: 24px 0 0;
}

.footer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
}

.Btn {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  text-align: left;

  &:hover {
    opacity: 0.75;
  }
}

.error {
  color: #d63031;
  composes: caption from "/src/index.css";
}

@include for-desktop-up {
  .main {
    grid-template-columns: $grid_layout_lg;
  }

  :global(.PublicOrder) {
    .main {
      grid-template-columns: $grid_layout_sm;
    }
  }
}
