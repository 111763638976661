@import "/src/styles";

$grid_layout_sm: 32px 112px 0.9fr 1fr 1fr 1fr;
$grid_layout_lg: 32px 112px 200px 242px 242px 242px;

.Elements {
  :global(.Element):last-of-type,
  :global(.Element__min):last-of-type {
    margin-bottom: 26px;
  }
}

.title {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.howToAddFiles {
  background: none;
  border: none;
  color: #4c83c3;
  composes: caption from "/src/index.css";
  cursor: pointer;
  display: inline-flex;
  font-weight: 700;

  &:hover {
    opacity: 0.75;
  }
}

.addFilesInstruction {
  list-style-type: initial;
  padding-left: 24px;
}

.addFilesInstruction_li {
  composes: body from "/src/index.css";
  display: list-item;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.noOfSets {
  color: #636e72;
  padding: 8px 0 4px 32px;

  :global(.Chip) {
    cursor: pointer;
  }
}

.messages {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &:not(:empty) {
    margin-bottom: 24px;
  }
}

.Message {
  align-items: center;
  border-radius: 4px;
  composes: body from "/src/index.css";
  display: flex;
  padding: 16px;

  .closeBtn {
    cursor: pointer;
    margin-left: auto;
  }
}

.Message__success {
  @extend .Message;

  background: #55efc4;
}

.Message__error {
  @extend .Message;

  background: #ff7675;
}

.selectionActions {
  align-items: center;
  display: flex;
  margin-bottom: 12px;

  :global(.Button) {
    display: inline-flex;
  }
}

.LoadingElement {
  border-bottom: 1px solid rgb(223, 230, 233);
  margin-bottom: 24px;
  margin-top: 24px;

  .main {
    align-items: start;
    display: grid;
    grid-template-columns: $grid_layout_sm;
    height: 112px;
  }
}

@include for-desktop-up {
  .LoadingElement {
    .main {
      grid-template-columns: $grid_layout_lg;
    }
  }

  :global(.PublicOrder) {
    .LoadingElement {
      .main {
        grid-template-columns: $grid_layout_sm;
      }
    }
  }
}
