.HowToCard {
  background: #ffeaa7;
  border-radius: 4px;
  composes: body from "/src/index.css";
  margin-left: 16px;
  padding: 16px;
  width: 368px;

  ul {
    list-style: disc;
    padding-left: 26px;

    li {
      color: #2d3436;
      display: list-item;
    }
  }
}

.header {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 6px;

  img {
    cursor: pointer;
  }
}
