.UrgentOffer {
  cursor: pointer;
  flex-shrink: 0;
  width: 480px;

  * {
    cursor: pointer;
  }
}

.desc {
  composes: caption from "/src/index.css";
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  column-gap: 24px;
  display: flex;

  .offer {
    width: 144px;
  }

  .client {
    flex: 1;
  }
}

.rate {
  align-items: center;
  display: flex;
  height: 20px;
  margin-bottom: 16px;

  :global(.ant-rate) {
    font-size: 12px;
    line-height: 12px;

    li {
      height: 12px;
    }
  }
}

.elements {
  align-items: center;
  column-gap: 16px;
  display: flex;
  margin-top: 12px;
}
