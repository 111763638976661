.App {
  background-color: #f2f4f5;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 64px 1fr;
  min-height: 100vh;
}

.App.App--without-menu {
  grid-template-columns: 1fr;
}

aside {
  display: none;
}

main {
  max-width: 100vw;
  grid-row-start: 2;
  padding: 33px 86px 86px 32px;
}

.AppBar {
  grid-column-end: 3;
  grid-column-start: 1;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.Menu {
  overflow: auto;
  position: sticky;
  top: 64px;
}

.app-loader {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

@media (min-width: 1200px) {
  .App {
    grid-template-columns: auto minmax(0, 1fr);
  }

  aside {
    display: block;
    grid-row-start: 2;
  }
}
