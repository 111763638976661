.MachineParameters {
  :global {
    .Parameter__edited + .Parameter__edited {
      margin-top: 4px;
    }

    .BendingRadiusToThicknessTable {
      margin-top: 8px;
      max-width: 100%;
      overflow: auto;
    }
  }
}
