.DrillingTable {
  background-color: white;
  border: 1px solid #e7edef;
  color: #2d3436;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  td,
  th {
    padding: 12px;
  }

  td {
    border-bottom: 1px solid #e7edef;
    border-top: 1px solid #e7edef;
  }

  th {
    color: #101212;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
  }

  :global(.InputNumber) {
    width: 50px;
  }

  td:first-of-type {
    white-space: nowrap;
  }

  thead {
    tr:first-of-type {
      border-bottom: 1px solid #e7edef;

      th {
        padding-bottom: 8px;
        padding-top: 8px;
      }
    }
  }
}
