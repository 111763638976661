.treeSelect {
  .selectable {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  :global {
    .ant-select-tree {
      .ant-select-tree-node-content-wrapper {
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
