.AdditionalProcessings {
  flex: 1;
  max-width: 399px;
  min-width: 230px;

  :global {
    .ProcessingType + .ProcessingType {
      margin-top: 24px;
    }
  }
}

.panel {
  :global {
    .ant-collapse-header {
      background-color: white;
      color: #101212;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px !important;
      padding: 24px;

      .ant-collapse-arrow {
        font-size: 16px !important;
      }
    }

    .ant-collapse-arrow {
      svg {
        transform: rotate(90deg) !important;
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-arrow {
        svg {
          transform: rotate(180deg) !important;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 24px;
    }
  }
}

.checkboxContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Info {
  align-items: flex-start;
  background: #ffeaa7;
  border-radius: 4px;
  composes: body from "/src/index.css";
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  padding: 16px;
  width: 399px;
}
