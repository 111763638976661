.Uploader {
  :global {
    .ant-upload.ant-upload-btn {
      padding: 0;
    }
  }
}

.box {
  align-items: center;
  background: rgba(116, 185, 255, 0.08);
  border: 1px dashed #74b9ff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  row-gap: 24px;
}

.dragInfo {
  color: #4c83c3;
  composes: caption from "/src/index.css";
  font-weight: 700;
}
