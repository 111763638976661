.table {
  margin-bottom: -12px;
  min-width: 393px;

  td {
    padding: 0;
  }

  tbody {
    tr {
      height: 64px;
    }
  }

  select {
    height: 40px;
  }
}
