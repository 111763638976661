.Services {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.select {
  display: inline-block;
  margin-right: 8px;
  width: 86%;

  :global {
    .ant-select {
      width: 100%;
    }
  }
}
