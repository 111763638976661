.root {
  display: flex;
  flex-direction: column;
}

.label {
  color: #101212;
  font-weight: 700;
  margin-bottom: 4px;
}

.input {
  background: #ffffff;
  border: 1px solid #b2bec3;
  border-radius: 2px;
  box-shadow: none;
  outline: none;
  width: 201px;

  &:hover {
    border: 1px solid #b2bec3;
  }

  &:focus,
  &:global(.ant-picker-focused) {
    border: 1px solid #4c83c3;
  }

  input {
    color: #101212;
    font-weight: 400;

    &::placeholder {
      color: #b2bec3;
    }
  }

  svg {
    fill: #101212;
  }
}

.default {
  .input {
    font-size: 14px;
    height: 40px;
    padding: 12px;
  }

  input {
    font-size: 14px;
    line-height: 16px;
  }

  .label {
    font-size: 13px;
    line-height: 16px;
  }
}

.small {
  .input {
    font-size: 13px;
    height: 32px;
    padding: 8px;
  }

  input {
    font-size: 13px;
    line-height: 16px;
  }

  .label {
    font-size: 11px;
    line-height: 12px;
  }
}

.large {
  .input {
    font-size: 15px;
    height: 52px;
    padding: 16px;
  }

  input {
    font-size: 15px;
    line-height: 20px;
  }

  .label {
    font-size: 13px;
    line-height: 16px;
  }
}

:global(.control-invalid) {
  .input {
    border: 1px solid #d63031;
  }
}
