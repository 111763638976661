.MachinePark {
  padding: 33px 32px 86px;
}

.main {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  :global {
    .Machine + .Machine {
      margin-top: 16px;
    }
  }
}

.machines {
  flex: 1;
  min-width: 650px;

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.technologies {
  max-width: 660px;
  padding-bottom: 40px;
  padding-right: 0;
}

.Empty {
  background: linear-gradient(
    90.03deg,
    rgba(116, 185, 255, 0.31) -0.14%,
    rgba(116, 185, 255, 0.16) 99.97%
  );
  border-radius: 4px;
  flex: 1;
  padding: 32px;
}
