.StatusInfoPanel {
  background-color: #00b894;
  border-radius: 4px;
  display: flex;
  padding: 24px;

  * {
    color: #ffffff !important;
  }

  :global(.h5) {
    white-space: nowrap;
  }
}

.col {
  margin-left: 48px;

  &:first-of-type {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    margin: 0;
    padding-right: 48px;
  }
}
