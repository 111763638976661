.AdditionalFilterModal {
  display: flex;
  flex-direction: column;
  gap: 24px;

  :global {
    .ant-picker {
      width: 100%;
    }
  }
}
