@import "/src/styles";

.header {
  align-items: center;
  display: flex;
}

.Element {
  border-bottom: 1px solid #dfe6e9;
  opacity: 0.35;
  padding-top: 24px;
}

.Element__checked {
  opacity: 1;
}

.Element_main {
  column-gap: 12px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 100px minmax(150px, 200px) 1fr 1fr 1fr 190px;
  row-gap: 12px;
  word-break: break-word;
}

.Element_footer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
}

@include for-tablet-landscape-up {
  .Element_main {
    display: grid;
    row-gap: 0;
  }
}
