@import "/src/styles";

.NewOrderPage {
  margin: 0 auto;
  padding: 32px 20px 86px 20px;
  width: 1136px;

  :global(.Elements) {
    flex: 1;
    margin-bottom: 32px;
  }

  :global(.AdditionalFiles) {
    margin-bottom: 32px;
  }
}

.pageTitle {
  composes: h3 from "/src/index.css";
  margin-bottom: 32px;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.elements_howToCard_section {
  align-items: flex-start;
  display: flex;
}

.backdrop {
  background: rgba(223, 230, 233, 0.65);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.verificationInfoCard {
  background: #ffeaa7;
  border-radius: 4px;
  display: flex;
  height: 158px;
  padding: 16px;
  position: absolute;
  width: 440px;
  z-index: 1;

  .arrowDown {
    bottom: -13px;
    position: absolute;
  }
}

@include for-desktop-up {
  .NewOrderPage {
    padding: 33px 0 145px;
  }
}
