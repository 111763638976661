.Checkbox {
  display: inline-flex;

  :global {
    .ant-checkbox-wrapper {
      align-items: center;

      &::after {
        content: "";
      }

      .ant-checkbox {
        top: 0;
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #4c83c3;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #4c83c3;
      border-color: #4c83c3;
    }
  }
}

.validator {
  height: 20px;
  opacity: 0;
  position: absolute;
  width: 20px;
  z-index: -1;
}

.default {
  :global {
    .ant-checkbox-inner {
      height: 20px;
      width: 20px;

      &::after {
        height: 10px;
        left: 27%;
        top: 51%;
        width: 6px;
      }
    }

    // NOTE: Label
    .ant-checkbox + span {
      color: #2d3436;
      font-size: 13px;
      line-height: 16px;
      padding-left: 12px;
      padding-right: 0;
    }
  }
}

.small {
  :global {
    .ant-checkbox-wrapper {
      .ant-checkbox-inner {
        height: 16px;
        width: 16px;
      }

      // NOTE: Label
      .ant-checkbox + span {
        color: #2d3436;
        font-size: 11px;
        line-height: 12px;
      }
    }
  }

  .validator {
    height: 16px;
    width: 16px;
  }
}
