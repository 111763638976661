.BendingMachinesLevelFields {
  :global(.InputNumber) {
    width: 64px;
  }
}

.field {
  align-items: center;
  composes: color-dark-gray from "/src/index.css";
  composes: caption from "/src/index.css";
  display: flex;
}
