.disabled {
  :global(.Card_Header) {
    border-bottom: none;
    opacity: 0.5;
    padding: 24px;
  }

  :global(.Card_Body) {
    display: none;
  }
}

.body {
  column-gap: 48px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
}

.desc {
  flex: 1;
  max-width: 631px;
}

.date {
  width: 220px;
}
