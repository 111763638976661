.card {
  margin: 0 auto;
  width: 550px;

  :global {
    .Card_Footer {
      text-align: center;
    }

    .ant-rate {
      font-size: 26px;
      line-height: 26px;
    }

    label {
      color: #101212 !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      line-height: 16px !important;
    }
  }
}
