@import "/src/styles";

.OfferDetailsModal {
  bottom: 3%;
  top: 5%;

  :global {
    .ant-modal-body {
      max-height: 63vh;
      overflow: auto;
    }
  }
}

.main {
  composes: body from "/src/index.css";
  display: flex;
  font-size: 13px;
  margin-bottom: 48px;

  & > div {
    flex: 1;
  }

  .rate {
    align-items: center;
    display: flex;
    height: 20px;
    margin-bottom: 2px;

    :global {
      .ant-rate {
        font-size: 12px;
        line-height: 12px;

        .ant-rate-star {
          cursor: pointer;
        }

        li {
          height: 12px;
        }
      }
    }
  }
}

.alerts {
  &:not(:empty) {
    margin-bottom: 48px;
  }

  .approvalRequiredAlert {
    background: #ffeaa7;
    border-radius: 4px;
    padding: 16px;
  }
}

.Element {
  border-bottom: 1px solid #dfe6e9;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  grid-template-columns: 200px 1fr 1fr 1fr 1fr;
  padding: 24px 0 16px;
}

.summary {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
}

.fileLink {
  color: #4c83c3;
  composes: body from "/src/index.css";
  margin-left: 8px;

  &:hover {
    color: #4c83c3;
    opacity: 0.75;
  }
}

@include for-desktop-up {
  .main {
    font-size: 15px;
  }

  .Element {
    column-gap: 8px;
    display: grid;
    row-gap: 0;
  }
}
