.Button {
  &:disabled {
    background: #4c83c3;
    cursor: not-allowed;
    opacity: 0.35;

    &:hover {
      background: #4c83c3;
      opacity: 0.35;
    }
  }
}

.Button_primary {
  background-color: #4c83c3;
  border: none;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
  padding: 0 16px;

  &:hover {
    color: #ffffff;
    opacity: 0.9;
  }

  a {
    color: inherit;
  }
}

.Button_secondary {
  background: #dfe6e9;
  border: none;
  border-radius: 2px;
  color: #101212;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;

  &:hover {
    color: #101212;
    opacity: 0.75;
  }

  &:disabled {
    background: #dfe6e9;
    cursor: not-allowed;
    opacity: 0.35;

    &:hover {
      background: #dfe6e9;
      cursor: not-allowed;
      opacity: 0.35;
    }
  }

  a {
    color: inherit;
  }
}

.btn {
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

.Button_outline {
  @extend .btn;

  background-color: transparent;
  border: 1px solid #2d3436;
  border-radius: 2px;
  color: #2d3436;

  &:disabled {
    background-color: transparent;
    color: #2d3436;

    &:hover {
      background-color: transparent;
      color: #2d3436;
    }
  }

  &:hover {
    color: #2d3436;
  }

  a {
    color: inherit;
  }
}

.Button_small {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 12px;
}

.Button_medium {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  padding: 12px 16px;
}

.Button_large {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  padding: 16px 20px;
}

.Button_link {
  @extend .btn;

  background-color: transparent;
  border: none;
  color: #4c83c3;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;

  &:hover {
    color: #4c83c3;
    opacity: 0.75;
  }
}
