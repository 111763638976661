.UserDetails {
  width: 320px;
}

.main {
  align-items: center;
  border-bottom: 1px solid #dfe6e9;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  padding-top: 24px;
}

.footer {
  padding: 16px 24px 24px;

  .logOutBtn {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 100%;
  }
}
