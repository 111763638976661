@import "/src/styles";

:global {
  .DashboardPage {
    padding: 32px 0 86px 20px;
  }
}

.urgent {
  display: flex;
  margin-bottom: 32px;
  max-width: 100vw;
  overflow: auto;

  :global(.OrderCard) {
    flex-shrink: 0;
  }

  :global(.Card) + :global(.Card) {
    margin-left: 24px;
  }

  :global {
    .Card {
      cursor: pointer;
      flex-shrink: 0;
      width: 480px;
    }
  }
}

.NoOrders {
  background-image: linear-gradient(
    90.03deg,
    rgba(116, 185, 255, 0.31) -0.14%,
    rgba(116, 185, 255, 0.16) 99.97%
  );
  background-position: center right 15%, 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  flex: 1;
  padding: 32px;

  :global {
    .Button {
      display: inline-block;
    }
  }
}

.NoOffers {
  :global {
    .Table_header {
      display: none;
    }

    .Table_content {
      padding-bottom: 64px;
    }
  }
}

@include for-tablet-portrait-up {
  .NoOrders {
    background-image: url("img/robot.png"),
      linear-gradient(
        90.03deg,
        rgba(116, 185, 255, 0.31) -0.14%,
        rgba(116, 185, 255, 0.16) 99.97%
      );
  }
}

@include for-desktop-up {
  :global {
    .DashboardPage {
      padding: 33px 86px 86px 32px;
    }
  }
}

@include for-desktop-down {
  :global {
    .DashboardPage {
      .Card {
        &:last-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}
