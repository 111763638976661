@import "/src/styles";

.Baner {
  align-items: center;
  background: linear-gradient(90deg, #4c83c3 0%, #609ee1 100%);
  display: none;
  flex-direction: column;
  min-height: 722px;
  padding: 45px 56px 25px 48px;
  width: 403px;

  * {
    color: #ffffff !important;
  }
}

.title {
  color: white;
  composes: h3 from "/src/index.css";
  margin-bottom: 39px;
}

.listItem {
  align-items: flex-start;
  display: flex;
  gap: 11px;

  & + & {
    margin-top: 17px;
  }
}

.actions {
  align-items: center;
  display: flex;
  gap: 24px;
  margin-top: 37px;
  padding-left: 2px;
  width: 100%;
}

.detailsBtn {
  background-color: transparent;
  border: none;
  composes: caption from "/src/index.css";
  cursor: pointer;
  font-weight: bold;
  padding: 0;

  &:hover {
    opacity: 0.75;
  }
}

.WhiteBtn {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  composes: caption from "/src/index.css";
  cursor: pointer;
  font-weight: 700;
  padding: 12px 16px;

  &:hover {
    opacity: 0.75;
  }
}

@include for-desktop-up {
  .Baner {
    display: flex;
  }
}
