.NewPasswordPage {
  display: flex;
  height: 100vh;
  padding: 0;

  :global {
    .PrivacyPolicy {
      margin-top: 48px;
    }
  }
}

.main {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 48px;

  form {
    margin-top: 48px;
    max-width: 100vw;
    width: 400px;
  }
}
