.Menu {
  border-right: 1px solid #c9d2d6;
  color: #2d3436;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 64px);
  padding: 24px 0;
  width: 280px;
}

.subTitle {
  color: #636e72;
  font-size: 13px;
  height: 52px;
  line-height: 16px;
  padding: 24px 32px 12px;
  pointer-events: none;
}

.menuItem {
  align-items: center;
  color: #2d3436;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  height: 56px;
  padding: 0 32px;

  &:hover {
    background: linear-gradient(
      89.83deg,
      rgba(116, 185, 255, 0.16) 0.73%,
      rgba(116, 185, 255, 0) 99.87%
    );
  }

  a {
    align-items: center;
    color: #2d3436;
    display: flex;
    padding: 16px 0;
    width: 100%;

    &:global(.active) {
      color: #4c83c3;
      font-weight: 700;

      path {
        fill: #4c83c3;
      }
    }

    span {
      line-height: 20px;
      margin-left: 16px;
    }
  }
}

.itemBtm {
  @extend .menuItem;

  cursor: default;
  margin-top: auto;
  padding-left: 0;

  &:hover {
    background: transparent;
  }

  img {
    cursor: pointer;
  }

  a {
    margin-right: 16px;
    padding-left: 32px;

    &:hover {
      background: linear-gradient(
        89.83deg,
        rgba(116, 185, 255, 0.16) 0.73%,
        rgba(116, 185, 255, 0) 99.87%
      );
    }
  }
}

.expandMenu {
  display: none;
}

.Menu__closed {
  @extend .Menu;

  width: 88px;

  .subTitle {
    display: none;
  }

  .menuItem {
    justify-content: center;
    padding: 0;

    a {
      width: auto;
    }

    span {
      display: none;
    }
  }

  .itemBtm {
    &:hover {
      background: linear-gradient(
        89.83deg,
        rgba(116, 185, 255, 0.16) 0.73%,
        rgba(116, 185, 255, 0) 99.87%
      );
    }

    a {
      margin: 0;
      padding-left: 0;
      width: auto;

      &:hover {
        background: transparent;
      }
    }

    & > img {
      display: none;
    }
  }

  .expandMenu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 16px 0;
  }
}
