.PrivacyPolicy {
  composes: caption from "/src/index.css";

  a {
    text-decoration: underline;

    &:hover {
      opacity: 0.75;
    }
  }
}
