.root {
  display: flex;
  min-height: 100vh;
  padding: 0;

  :global {
    .Baner {
      padding-left: 30px;
      padding-right: 30px;
      width: 330px;

      img {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 100%;
      }
    }
  }
}

.main {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  padding-top: 70px;
}

.card {
  max-width: 600px;
}
