.ViewerModal {
  :global {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.empty {
  padding-bottom: 60px;
  padding-top: 20px;
  text-align: center;
}

.empty_text {
  composes: body from "/src/index.css";
  font-size: 18px;
  line-height: 1.3;
  margin-top: 40px;
}
