.MaterialsLevel {
  .header {
    align-items: flex-start;
    color: #101212;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    gap: 16px;
    justify-content: flex-start;
    line-height: 20px;
    margin-bottom: 12px;

    img {
      cursor: pointer;
    }
  }
}
