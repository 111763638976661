@import "src/styles";

.PublicOrder {
  display: flex;
  min-height: 100vh;
  padding: 0;

  :global {
    .Baner {
      padding-left: 30px;
      padding-right: 30px;
      width: 330px;

      img {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 100%;
      }
    }

    .NewOrderPage {
      width: auto;
    }
  }

  @include for-desktop-up {
    :global {
      .NewOrderPage {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        width: auto;
      }
    }
  }
}

.info {
  align-items: start;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  max-width: 800px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

  .infoMain {
    flex-shrink: 0;
    width: 150px;
  }

  .dash {
    margin-top: 60px;
    width: 26px;
  }

  .dashOnlyBig {
    @extend .dash;

    @media screen AND (max-width: 1350px) {
      display: none;
    }
  }

  .title {
    color: #4c83c3;
    font-size: 15px;
    font-weight: 600;
    left: -20px;
    margin-bottom: 6px;
    margin-top: 15px;
    position: relative;
    white-space: nowrap;
    width: 180px;
  }

  .text {
    font-weight: 500;
    left: -20px;
    position: relative;
    width: 180px;
  }

  img {
    max-width: 120px;
  }
}

.main {
  flex: 1 1;
  padding-left: 33px;
  padding-right: 40px;
  padding-top: 48px;
}

.contactForm {
  column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  width: 600px;
}
