.Alert {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
}

.info_section {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.title {
  color: #101212;
  composes: body from "/src/index.css";
  font-weight: 700;
}
