@import "/src/styles";

.OrderPage {
  padding: 33px 32px 86px 32px;

  :global {
    .StatusInfoPanel {
      margin: 32px 0;
    }

    .Elements {
      margin-top: 32px;
    }
  }
}

.ratings {
  cursor: pointer;
  text-decoration: underline;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;

  .headerActions {
    align-items: flex-start;
    column-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 10px;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 24px;
  row-gap: 10px;
}

.descCustomerSection {
  column-gap: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  & > * {
    flex: 1;
  }
}

@include for-tablet-landscape-up {
  .descCustomerSection {
    flex-direction: row;
  }
}

@include for-desktop-up {
  .OrderPage {
    padding: 33px 86px 86px 32px;
  }
}
