.DocsProtectionChoice__readonly {
  span,
  label,
  input {
    cursor: default;
    pointer-events: none;
  }

  .tooltip {
    pointer-events: auto;
  }
}
