.Services {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.select {
  margin-right: 8px;
  width: 86%;
}
