.Counter {
  align-items: center;
  display: inline-flex;

  :global(.ant-input-number-handler-wrap) {
    display: none;
  }

  :global(.InputNumber) {
    border: 1px solid #b2bec3;
    border-radius: 2px;
    height: 32px;
    margin-left: 8px;
    margin-right: 8px;
    width: 40px;

    input {
      color: #101212;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      padding: 8px;
      text-align: center;
    }
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;

    &[disabled] {
      cursor: not-allowed;
    }
  }
}
