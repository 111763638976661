.MachinesLevel {
  &:not(:last-of-type) {
    border-bottom: 1px solid #dfe6e9;
    padding-bottom: 24px;
  }

  &:not(:first-of-type) {
    padding-top: 24px;
  }

  :global {
    .Machine + .Machine {
      margin-top: 18px;
    }

    .MaterialsLevel + .MaterialsLevel {
      margin-top: 18px;
    }

    .MaterialsLevel:last-of-type {
      margin-bottom: 24px;
    }
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
  }
}

.Machine {
  .header {
    align-items: center;
    color: #101212;
    display: flex;
    font-size: 15px;
    font-weight: 700;
    gap: 16px;
    justify-content: flex-start;
    line-height: 20px;
    margin-bottom: 2px;

    img {
      cursor: pointer;
    }
  }
}
