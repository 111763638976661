.ContactInfoModal {
}

.form {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
}

.permissions {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 16px;

  :global {
    .Checkbox {
      font-weight: 500;
    }
  }

  .explanation {
    font-style: italic;
    padding-left: 32px;
  }
}
