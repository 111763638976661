.wrapper {
  display: flex;

  :global {
    .ant-modal-header {
      border-bottom: 1px solid #dfe6e9;
      padding: 24px 24px 16px;

      .ant-modal-title {
        color: #101212;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    .ant-modal-body {
      padding: 24px;
    }

    .ant-modal-footer {
      border-top: 1px solid #dfe6e9;
      padding-bottom: 24px;
      padding-right: 24px;
      padding-top: 17px;
    }

    .ant-modal-close-x {
      height: 65px;
      line-height: 74px;
      width: 65px;

      svg {
        fill: #101212;
      }
    }
  }
}

.cancelBtn {
  margin-right: 16px;
}
