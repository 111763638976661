.main {
  align-items: flex-start;
  display: flex;
  gap: 24px;
}

.Empty {
  background: linear-gradient(
    90.03deg,
    rgba(116, 185, 255, 0.31) -0.14%,
    rgba(116, 185, 255, 0.16) 99.97%
  );
  border-radius: 4px;
  flex: 1;
  padding: 32px;
}

.priceLists {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  min-width: 550px;
}

.PricesPage {
  :global {
    .AdditionalInfo {
      flex: 1;
      max-width: 399px;
      min-width: 215px;
    }

    .PriceList {
      .PriceList_header {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .PriceList_field {
        .InputNumber {
          width: 64px;
        }
      }
    }
  }
}
