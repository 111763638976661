.SearchOrdersPage {
  padding: 32px;

  :global {
    .Filters {
      margin-bottom: 16px;
    }

    .ListItem + .ListItem {
      margin-top: 16px;
    }

    .SubscriptionDisabled {
      margin-top: 70px;
    }
  }

  .link {
    color: #4c83c3;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: #4c83c3;
      opacity: 0.75;
      text-decoration: underline;
    }
  }
}

.noResults {
  :global(.Table_header) {
    display: none;
  }
}

.loader {
  color: #4c83c3;
  font-size: 16px;
  margin-bottom: auto;
  margin-top: auto;
}
