.EmailNotConfirmedWarning {
  align-items: center;
  background: #ffeaa7;
  border-radius: 4px;
  composes: body from "/src/index.css";
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
}
