.Chat {
  margin-top: 32px;
}

.Message {
  line-height: 1.4;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 20px;
  }
}
