@import "/src/styles";

.MyOffersPage {
  padding: 32px 32px 72px;

  :global {
    .SubscriptionDisabled {
      margin-top: 70px;
    }
  }
}

.urgentList {
  column-gap: 24px;
  display: flex;
  margin-bottom: 32px;
  overflow: auto;
}

@include for-desktop-up {
  .MyOffersPage {
    padding: 33px 86px 86px 32px;
  }
}
