.name {
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 20px;
}

.sectionTitle {
  color: #101212;
  font-size: 21px;
  font-weight: 500;
}

.Review {
  display: flex;

  & + & {
    margin-top: 12px;
  }
}
