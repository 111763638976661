@import "/src/styles";

.SubscriptionDisabled {
  :global(.Card_Body) {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 60px;
    padding: 32px;
  }
}

@include for-tablet-portrait-up {
  .SubscriptionDisabled {
    :global(.Card_Body) {
      flex-direction: row;
    }
  }
}
