.Notifications {
  cursor: pointer;
  margin-left: auto;
  position: relative;
}

.countBadge {
  align-items: center;
  background: #d63031;
  border-radius: 16px;
  color: #ffffff;
  display: flex;
  font-size: 9px;
  font-weight: 700;
  height: 16px;
  justify-content: center;
  left: 12px;
  line-height: 12px;
  position: absolute;
  top: -5px;
  width: 16px;
}

.popover {
  border: 1px solid #c9d2d6;
  border-radius: 4px;
  box-shadow: 0 4px 32px rgba(45, 52, 54, 0.08);
  max-height: 320px;
  overflow-y: auto;
  padding: 0;
  top: 54px !important;
  width: 320px;
  z-index: 10000;

  :global {
    .Notification + .Notification {
      margin-top: 4px;
    }

    .ant-popover-inner-content {
      padding: 24px 0;
    }
  }
}
