.Notification {
  cursor: pointer;
  display: flex;
  gap: 12px;
  padding: 4px 24px;

  &:hover {
    background: linear-gradient(
      89.83deg,
      rgba(116, 185, 255, 0.16) 0.73%,
      rgba(116, 185, 255, 0) 99.87%
    );
  }
}

.bulb {
  background: #d63031;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.seen {
  .bulb {
    background: #e7edef;
  }
}
