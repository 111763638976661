.Files {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.uploadBtn {
  position: relative;

  &:hover {
    opacity: 0.9;
  }

  img {
    margin: 0;
  }

  label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.addFile {
  img {
    cursor: pointer;
    margin-left: 4px;
    margin-right: 0;
  }
}
