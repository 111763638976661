.Navigation {
  margin-left: 32px;

  :global {
    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #4c83c3;
      }
    }

    .ant-tabs-ink-bar {
      background: #4c83c3;
    }

    .ant-tabs-tab:hover {
      color: #4c83c3;
    }

    .ant-tabs-tab-btn {
      color: #101212;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
