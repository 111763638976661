.Card {
  background: #ffffff;
  border: 1px solid #c9d2d6;
  border-radius: 4px;
  box-sizing: border-box;
}

.header {
  border-bottom: 1px solid #dfe6e9;
  padding: 24px 24px 16px;
}

.body {
  padding: 24px;
}

.footer {
  border-top: 1px solid #dfe6e9;
  padding: 16px 24px 24px;
}
