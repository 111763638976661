.AddNextBtn {
  align-items: center;
  color: #4c83c3;
  cursor: pointer;
  display: flex;

  &:hover {
    opacity: 0.75;
  }
}
