@import "/src/styles";

.ListItem {
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
}

.imgContainer {
  align-items: center;
  border: 1px solid #dfe6e9;
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 8px;
  height: 216px;
  justify-content: center;
  margin-right: 24px;
  overflow: hidden;
  padding: 7px;
  width: 216px;

  img {
    height: 96px;
    width: 96px;
  }

  .extraImgs {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 96px;
    justify-content: center;
    width: 96px;

    img {
      height: 48px;
      width: 48px;
    }
  }
}

.client {
  composes: caption from "/src/index.css";
}

.rate {
  align-items: center;
  display: inline-flex;
  height: 20px;
  margin-left: 12px;

  :global(.ant-rate) {
    font-size: 12px;
    line-height: 12px;

    li {
      height: 12px;
    }
  }
}

@include for-tablet-portrait-up {
  .ListItem {
    flex-direction: row;
    gap: 0;
  }

  .client {
    flex: 0 0 320px;
    margin-left: auto;
  }
}
