.services {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.service {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;

  :global(.DrillingTable) {
    min-width: 350px;
  }
}
