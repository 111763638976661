@import "/src/styles";

.OrderPage {
  padding: 33px 32px 86px 32px;

  :global {
    .StatusInfoPanel {
      margin: 32px 0;
    }

    .Elements {
      margin-top: 24px;
    }

    .AdditionalFiles {
      margin-top: 24px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;

  .headerActions {
    align-items: flex-start;
    column-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
  }
}

.footerActions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 24px;

  .footerActions_right {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-end;
    margin-left: auto;
  }
}

// Offers
.companyName {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.ratings {
  cursor: pointer;
  margin-left: 8px;

  &:hover {
    opacity: 0.9;
  }
}

:global(.ant-rate) {
  font-size: 12px;

  :global(.ant-rate-star) {
    margin: 0;
  }
}

.link {
  composes: body from "/src/index.css";
  cursor: pointer;

  &:hover {
    color: inherit;
    opacity: 0.9;
    text-decoration: underline;
  }
}

.descAndDocsProtectionSection {
  align-items: flex-start;
  column-gap: 45px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;

  .desc {
    flex: 1;
  }

  .docsProtection {
    width: 400px;
  }
}

// Offers - END

.Alert {
  align-items: flex-start;
  background: #55efc4;
  border-radius: 4px;
  color: #101212;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 16px;

  img {
    cursor: pointer;
  }
}

.cellGrayed {
  opacity: 0.35;
}

@include for-tablet-portrait-down {
  :global {
    .StatusInfoPanel {
      flex-direction: column;
      gap: 10px;

      & > div {
        border-right: none !important;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@include for-desktop-up {
  .OrderPage {
    padding: 33px 86px 86px 32px;
  }

  .header {
    flex-direction: row;
    gap: 0;
  }
}
