.bendingTable {
  background-color: white;
  border: 1px solid #e7edef;
  color: #2d3436;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  td,
  th {
    padding: 12px;
  }

  td {
    border-bottom: 1px solid #e7edef;
    border-top: 1px solid #e7edef;

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      padding: 4px;
    }
  }

  th {
    color: #101212;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;

    &:nth-child(3) {
      padding: 4px;
    }
  }

  :global(.InputNumber) {
    width: 50px;
  }

  td:first-of-type {
    white-space: nowrap;
  }
}
