@import "/src/styles";

.UsersPage {
  padding: 33px 32px 86px 32px;

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  :global {
    .Table {
      .Table_header {
        flex-wrap: wrap;
        gap: 4px;
        justify-content: space-between;

        .Table_actions {
          margin-left: 0;
        }
      }

      .Table_th {
        &:nth-child(2),
        &:nth-child(3) {
          text-align: center;

          .Table_th-container {
            display: inline-flex;
          }
        }
      }

      .Table_td {
        &:nth-child(2),
        &:nth-child(3) {
          text-align: center;
        }
      }
    }
  }
}

@include for-desktop-up {
  .UsersPage {
    padding: 33px 86px 86px 32px;
  }
}
