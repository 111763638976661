.Chip {
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  cursor: default;
  display: inline-flex;
  flex-direction: row;
  font-weight: 700;
  justify-content: center;

  img {
    margin-right: 4px;
  }
}

.green {
  background-color: #00b894;
}

.red {
  background-color: #d63031;
}

.lightRed {
  background-color: rgba(250, 177, 160, 0.32);
  color: #d63031;
}

.purple {
  background-color: #6c5ce7;
}

.orange {
  background-color: #fdcb6e;
  color: #101212;
}

.darkGray {
  background-color: #636e72;
  color: #ffffff;
}

.gray {
  background-color: #dfe6e9;
  color: #101212;
}

.blue {
  background-color: #4c83c3;
  color: #ffffff;
}

.ginger {
  background-color: #e17055;
  color: #ffffff;
}

.small {
  font-size: 11px;
  line-height: 12px;
  min-height: 20px;
  padding: 2px 4px;
}

.caption {
  font-size: 13px;
  line-height: 16px;
  min-height: 24px;
  padding: 4px 8px;
}

.body {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  padding: 2px 8px;
}
