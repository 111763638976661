@import "/src/styles";

.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 25px;
}

.Element {
  border-bottom: 1px solid #dfe6e9;
  opacity: 0.35;
  padding-top: 24px;
}

.Element__checked {
  opacity: 1;
}

.Element_main {
  column-gap: 60px;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 20px 100px minmax(150px, 200px) 1fr 1fr 1fr minmax(
      150px,
      190px
    );
  row-gap: 15px;
  word-break: break-word;
}

.Element_footer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
}

.price {
  align-items: center;
  display: flex;

  :global(.InputNumber) {
    flex: 1;
  }
}

.checkbox {
  align-self: center;

  :global {
    .ant-checkbox-input,
    .ant-checkbox-inner {
      height: 20px;
      width: 20px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
    }
  }
}

@include for-tablet-landscape-up {
  .Element_main {
    column-gap: 12px;
    display: grid;
  }
}
