.main {
  display: flex;
  gap: 20px;

  & > div {
    flex: 1;
  }
}

.imgContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.inputs {
  align-items: flex-start;
  composes: caption from "/src/index.css";
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;

  & > div {
    align-items: center;
    display: grid;
    gap: 8px;
    grid-template-columns: 115px 63px 1fr;

    :global(.InputNumber) {
      max-width: 100%;
    }
  }
}
