body {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  align-items: center;
  display: flex;
}

h1 {
  font-size: 36px;
  margin: 0;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  opacity: 0.75;
}

/* Typography */

.h2 {
  color: #101212;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.h3 {
  color: #101212;
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
}

.h4 {
  color: #000000;
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
}

.h5 {
  color: #101212;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.h6 {
  color: #101212;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.link {
  color: #4c83c3;
  text-decoration: underline;
}

.link:hover {
  color: #4c83c3;
  opacity: 0.75;
  text-decoration: underline;
}

.small {
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
}

.caption {
  color: #636e72;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.body {
  color: #101212;
  font-size: 15px;
  line-height: 20px;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 500;
}

.color-black {
  color: #101212;
}

.color-dark-gray {
  color: #2d3436;
}

.color-gray {
  color: #636e72;
}

.color-primary {
  color: #4c83c3;
}

.color-red {
  color: #d63031;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-inline-block {
  display: inline-block;
}

.d-flex-col-center {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  margin: 0;
}

/* END - Typography */

/* antd */
.ant-select .ant-select-selector {
  border: 1px solid #b2bec3 !important;
  box-shadow: none !important;
  outline: none !important;
}

.ant-select.ant-select-focused .ant-select-selector {
  border-color: #4c83c3 !important;
}

.ant-modal-mask,
.ant-modal-wrap,
.ant-tooltip,
.ant-picker-dropdown,
.ant-select-dropdown {
  z-index: 10000;
}

.ant-notification {
  z-index: 10001;
}

.ant-spin-dot-item {
  background-color: #4c83c3;
}
/* END - antd*/

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.pulse-animation {
  animation: pulse-animation 2s;
  animation-iteration-count: 5;
}
