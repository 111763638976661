.services {
  & > div {
    margin-bottom: 48px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.body {
  composes: caption from "/src/index.css";
  display: flex;
}

.amount {
  display: flex;
  flex: 0 0 159px;
  gap: 8px;
  margin-right: auto;
}

.main {
  flex: 0 1 auto;
}
