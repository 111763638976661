.root {
  display: flex;
  flex-direction: column;

  &:global(.control-invalid) {
    :global {
      .ant-select-selector {
        border-color: #d63031 !important;
      }
    }
  }
}

.label {
}

.select {
  color: #101212;

  :global {
    .ant-select-selector {
      align-items: center;
      border-color: #b2bec3 !important;
      border-radius: 2px !important;
      display: flex;
    }

    .ant-select-selection-placeholder {
      color: #101212;
    }
  }

  &:global(.ant-select-focused:not(.ant-select-disabled)) {
    :global(.ant-select-selector) {
      border: 1px solid #4c83c3 !important;
      box-shadow: none !important;
    }
  }
}

.default {
  .select {
    font-size: 14px;
    line-height: 16px;

    :global {
      .ant-select-selector {
        min-height: 40px !important;
        padding: 12px;
      }

      .ant-select-selection-search-input {
        height: 38px;
      }
    }
  }
}

.small {
  .select {
    font-size: 13px;
    line-height: 16px;

    :global {
      .ant-select-selector {
        min-height: 32px !important;
        padding: 8px;
      }
    }

    // NOTE: Multiple
    &:global(.ant-select-multiple) {
      :global(.ant-select-selector) {
        padding: 1px 4px;
      }
    }
  }
}

.large {
  .select {
    font-size: 14px;
    line-height: 16px;

    :global {
      .ant-select-selector {
        min-height: 40px !important;
        padding: 6px 8px;

        .ant-select-selection-placeholder {
          font-size: 13px;
        }
      }
    }
  }
}
