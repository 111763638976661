.SelectMachineModal {
}

.main {
  display: flex;
  gap: 36px;

  :global {
    .Select,
    .Input {
      width: 344px;
    }
  }
}
