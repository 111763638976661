.actions {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-end;
  margin-left: auto;
  row-gap: 10px;

  :global(.Button) {
    display: flex;

    img {
      margin-right: 12px;
    }

    &:global(.Button_medium) {
      img {
        height: 16px;
        margin-right: 8px;
        width: 16px;
      }
    }
  }
}
