.Chat {
  margin-top: 32px;
}

.Message {
  line-height: 1.4;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 20px;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Provider {
  align-items: center;
  border: 1px solid rgba(223, 230, 233, 0.34);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  padding: 2px 4px;

  &:hover {
    background: #4c83c3;
    color: #fff;
  }
}

.Provider__active {
  background: #4c83c3;
  color: #fff;
}

@media (min-width: 768px) {
  .layout {
    display: grid;
    grid-template-columns: 170px 1fr;
  }
}
