.InputNumber {
  border: 1px solid #b2bec3;
  box-shadow: none;
  outline: none;

  input {
    color: #101212;

    &::placeholder {
      color: #b2bec3;
    }
  }

  &:focus,
  &:hover {
    border: 1px solid #4c83c3;
  }

  &:global(.control-invalid) {
    border-color: #d63031;
  }
}

.large {
  input {
    font-size: 15px;
    height: 52px;
    line-height: 20px;
    padding: 16px;

    &::placeholder {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.small {
  input {
    font-size: 13px;
    height: 32px;
    line-height: 16px;
    padding: 8px;

    &::placeholder {
      font-size: 13px;
      line-height: 16px;
    }
  }

  :global {
    .ant-input-number-handler-wrap {
      width: 12px;
    }

    .anticon {
      right: 0;
    }
  }
}
