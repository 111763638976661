.root {
  display: flex;
  flex-direction: column;

  textarea {
    background: #ffffff;
    border: 1px solid #b2bec3;
    border-radius: 2px;
    box-sizing: border-box;
    color: #101212;
    font-weight: 400;
    outline: none;

    &::placeholder {
      color: #b2bec3;
    }

    &:focus {
      border: 1px solid #4c83c3;
    }
  }
}

.label {
  color: #101212;
  font-weight: 700;
  margin-bottom: 4px;
}

.default {
  textarea {
    font-size: 14px;
    line-height: 16px;
    padding: 12px;
  }

  .label {
    font-size: 13px;
    line-height: 16px;
  }
}

.small {
  textarea {
    font-size: 11px;
    line-height: 12px;
    padding: 8px;
  }

  .label {
    font-size: 11px;
    line-height: 12px;
  }
}

.large {
  textarea {
    font-size: 15px;
    line-height: 20px;
    padding: 16px;
  }

  .label {
    font-size: 13px;
    line-height: 16px;
  }
}
