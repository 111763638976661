.Input {
  display: flex;
  flex-direction: column;

  input {
    border: 1px solid #b2bec3;
    box-shadow: none;
    color: #101212;
    outline: none;

    &::placeholder {
      color: #b2bec3;
    }

    &:focus,
    &:hover {
      border: 1px solid #4c83c3;
    }
  }
}

.default {
  input {
    font-size: 14px;
    height: 40px;
    line-height: 16px;
    padding: 12px;

    &::placeholder {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .label {
    color: #101212;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 4px;
  }
}

.large {
  input {
    font-size: 15px;
    height: 52px;
    line-height: 20px;
    padding: 16px;

    &::placeholder {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .label {
    color: #101212;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 4px;
  }
}

.small {
  input {
    font-size: 13px;
    height: 32px;
    line-height: 16px;
    padding: 8px;

    &::placeholder {
      font-size: 13px;
      line-height: 16px;
    }
  }

  :global {
    .ant-input-number-handler-wrap {
      width: 12px;
    }

    .anticon {
      right: 0;
    }
  }
}
