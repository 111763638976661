.ColorSelect {
  :global {
    .ant-select {
      border-radius: 4px !important;
      width: 80px;

      .ant-select-selector {
        background-color: inherit !important;
        border: none !important;
        border-radius: 4px !important;
        font-size: 13px !important;
        font-weight: 700 !important;
        height: 24px !important;
        line-height: 16px !important;
        padding: 0 8px !important;

        .ant-select-selection-item {
          color: inherit;
          line-height: 24px;
        }
      }

      .ant-select-selection-search {
        display: none;
      }
    }
  }

  &:global(.control-invalid) {
    :global {
      .ant-select {
        border-color: #d63031 !important;
      }
    }
  }
}
