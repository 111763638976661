.RegisterPage {
  display: flex;
  min-height: 100vh;
  padding: 0;

  :global {
    .PrivacyPolicy {
      margin-bottom: 30px;
      margin-top: 48px;
    }
  }
}

.main {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 48px;

  form {
    margin-top: 48px;
    max-width: 100vw;
    width: 400px;
  }
}

.hiddenValidator {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
