@import "/src/styles";

:global {
  .MyOrdersPage {
    padding: 32px 0 86px 20px;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding-right: 20px;
}

.urgent {
  display: flex;
  margin-bottom: 32px;
  overflow: auto;

  :global(.OrderCard) {
    flex-shrink: 0;
  }

  :global(.OrderCard) + :global(.OrderCard) {
    margin-left: 24px;
  }
}

.OrderCreatedAlert {
  align-items: center;
  background: #55efc4;
  border-radius: 4px;
  color: #101212;
  display: flex;
  margin-bottom: 30px;
  padding: 16px;

  img {
    cursor: pointer;
  }
}

.Actions {
  align-items: center;
  display: flex;
  gap: 8px;
}

@include for-desktop-up {
  :global {
    .MyOrdersPage {
      padding: 33px 86px 86px 32px;
    }
  }

  .header {
    padding-right: 0;
  }
}

@include for-desktop-down {
  :global {
    .MyOrdersPage {
      .Card {
        &:last-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}
