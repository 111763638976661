.Bending {
  composes: caption from "/src/index.css";
}

.input {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 88px 63px min-content;

  :global(.InputNumber) {
    max-width: 100%;
  }
}

.withBtn {
  align-items: center;
  display: flex;
  gap: 18px;

  img {
    cursor: pointer;
  }
}

.main {
  display: flex;
  gap: 16px;
}

.Radius {
  align-items: flex-start;
  border-bottom: 1px solid #dfe6e9;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
  padding-bottom: 12px;

  & + & {
    margin-top: 24px;
  }
}

.LineLength {
  align-items: flex-start;
  border-bottom: 1px solid #dfe6e9;
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
  padding-bottom: 12px;

  & + & {
    margin-top: 12px;
  }
}

.Angle {
  border-bottom: 1px solid #dfe6e9;
  display: flex;

  .Angle_input {
    @extend .input;

    margin-bottom: 8px;
  }
}

.hovered {
  background-color: #f2f4f5;
}
