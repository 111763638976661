.materialWarning {
  align-items: flex-start;
  background: #ffeaa7;
  border-radius: 4px;
  composes: body from "/src/index.css";
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  padding: 16px;
}
