@import "/src/styles";

.GeneralPage {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 33px 32px 86px 32px;

  section {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  :global {
    .Card {
      max-width: 720px;
    }
  }

  form {
    & > * {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.twoInRow {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  & > * {
    flex: 1;
  }
}

@include for-desktop-up {
  .GeneralPage {
    padding: 33px 86px 86px 32px;
  }
}
