.files {
  li {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 24px;
    }
  }
}

.removeBtn {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 2px;
}
