.main {
  display: flex;
  gap: 16px;

  & > div {
    flex: 1;
  }
}

.imgContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.input {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 115px 63px 1fr;
  margin-bottom: 28px;

  :global(.InputNumber) {
    max-width: 100%;
  }
}

.colors {
  composes: caption from "/src/index.css";
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Color {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: 96px 115px 63px 1fr 1fr;

  :global(.InputNumber) {
    max-width: 100%;
  }
}
