.Pagination {
  align-items: center;
  display: flex;
  height: 42px;

  :global(.Button) {
    display: inline-flex;
  }
}

.cell {
  composes: body from "/src/index.css";
  cursor: pointer;
  padding: 8px;
}

.perPage {
  align-items: center;
  color: #000000;
  composes: caption from "/src/index.css";
  display: flex;
  margin-right: 32px;
}
