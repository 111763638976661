@import "/src/styles";

.OfferPage {
  padding-right: 32px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 32px;
}

.notifications {
  align-items: center;
  display: flex;
  margin: 24px 0;
}

.descCustomerSection {
  column-gap: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  & > * {
    flex: 1;
  }
}

.summary {
  margin-top: 24px;

  .desc {
    margin-right: 40px;
    width: 370px;
  }

  .price {
    margin-left: auto;
    text-align: right;
  }

  :global {
    .Card_Body {
      flex-wrap: wrap;
      row-gap: 15px;
    }
  }
}

.fileLink {
  color: #4c83c3;
  composes: body from "/src/index.css";
  margin-left: 8px;

  &:hover {
    color: #4c83c3;
    opacity: 0.75;
  }
}

.footerActions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 24px;
}

@include for-tablet-landscape-up {
  .descCustomerSection {
    flex-direction: row;
  }
}
