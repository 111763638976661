@import "/src/styles";

.main {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.technologies {
  max-width: 100%;
  width: 447px;
}

.sort {
  margin-right: auto;
  min-width: 190px;
}

.openFiltersButton {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.link {
  color: #4c83c3;
  font-weight: 700;
  margin-left: 8px;
  text-decoration: underline;

  &:hover {
    color: #4c83c3;
    opacity: 0.75;
    text-decoration: underline;
  }
}

@include for-tablet-landscape-up {
  .main {
    align-items: flex-end;
    flex-direction: row;
    gap: 0;
  }

  .openFiltersButton {
    margin-left: 16px;
  }

  .sort {
    margin-left: auto;
    margin-right: 0;
  }
}
