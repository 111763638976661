.formInputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.link {
  color: #4c83c3;
  composes: caption from "/src/index.css";
  font-weight: 700;

  &:hover {
    opacity: 0.75;
  }
}
