@import "/src/styles";

.modal {
  bottom: 15px;
}

.validityInfo {
  align-items: center;
  column-gap: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  row-gap: 2px;
}

.main {
  column-gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
}

.services {
  margin-top: 52px;
}

.services_title {
  border-bottom: 1px solid #dfe6e9;
  color: #636e72;
  composes: body from "/src/index.css";
  padding-bottom: 12px;
}

.imgContainer {
  border: 1px solid #c9d2d6;
  display: inline-block;
  height: 200px;
  margin-bottom: 12px;
  width: 200px;

  img {
    height: 100%;
    width: 100%;
  }
}

@include for-tablet-portrait-up {
  .main {
    flex-direction: row;
  }
}
