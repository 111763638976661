@import "/src/styles";

.Table {
  .Table_header {
    display: flex;

    .Table_count {
      color: #101212;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .Table_actions {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-left: auto;
    }
  }

  .Table_content {
    overflow-x: auto;

    table {
      table-layout: fixed;
      width: 100%;
    }

    .Table_row--head {
      height: 45px;

      .Table_th {
        color: #101212;
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        overflow: hidden;
        padding: 12px;
        text-align: left;
        text-overflow: ellipsis;

        .Table_th-container {
          align-items: center;
          display: flex;

          .Table_th-sort {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-left: 4px;

            img {
              height: 11px;
              width: 11px;

              &:first-of-type {
                margin-bottom: -1px;
              }

              &:last-of-type {
                margin-top: -1px;
              }
            }
          }
        }
      }

      .Table_th--sortable {
        cursor: pointer;
      }
    }

    .Table_row--body {
      border-top: 1px solid #f2f4f5;
      height: 49px;

      .Table_td {
        color: #2d3436;
        font-size: 15px;
        line-height: 20px;
        overflow: hidden;
        padding: 12px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;

        .Table_details-btn {
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .Table_row--clickable {
      cursor: pointer;

      &:hover {
        background-color: #efefef4d;
      }
    }
  }

  .Table--empty {
    text-align: center;

    .h5 {
      margin-top: 12px;
    }

    .caption {
      margin-top: 4px;
    }
  }
}

@include for-desktop-down {
  .Table {
    .Table_content {
      td {
        max-width: 200px;
      }

      table {
        table-layout: auto;
      }
    }
  }
}
