@import "/src/styles";

.NewOfferPage {
  padding-right: 32px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;
  row-gap: 16px;

  .headerActions {
    align-items: flex-start;
    column-gap: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 10px;
  }
}

.notifications {
  align-items: center;
  display: flex;
  margin: 24px 0;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 24px;
  row-gap: 10px;
}

.descCustomerSection {
  column-gap: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  & > * {
    flex: 1;
  }
}

.summary {
  margin-top: 24px;

  .desc {
    margin-right: 40px;
    width: 370px;
  }

  .price {
    margin-left: auto;
    text-align: right;
  }

  :global {
    .Card_Body {
      flex-wrap: wrap;
      row-gap: 15px;
    }
  }
}

.offerFiles {
  margin-bottom: 16px;
  margin-top: 8px;

  li {
    margin-bottom: 8px;
  }

  .offerFiles_removeBtn {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
}

@include for-tablet-landscape-up {
  .descCustomerSection {
    flex-direction: row;
  }
}
