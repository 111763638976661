.OrderCard {
  cursor: pointer;
  width: 480px;

  * {
    cursor: pointer;
  }
}

.header {
  min-height: 89px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :global(.h5) {
    display: block;
    margin-bottom: 8px;

    &:hover {
      color: #101212;
      opacity: 0.75;
    }
  }
}

.desc {
  composes: caption from "/src/index.css";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main {
  color: #2d3436;
  composes: caption from "/src/index.css";
  display: flex;
  flex-direction: column;
}

.tags {
  display: flex;

  :global(.Chip) {
    font-weight: 400;
    margin-right: 8px;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.lowestOffer {
  :global(.h5) {
    margin-top: 2px;
  }
}

.details {
  width: 264px;

  & > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.elements {
  margin-top: 24px;
}

.thumbnails {
  align-items: center;
  display: flex;
  margin-top: 12px;

  img {
    border: 1px solid #dfe6e9;
    border-radius: 4px;
    height: 72px;
    margin-right: 16px;
    width: 72px;
  }
}
