@import "/src/styles";

.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  :global(.Button) {
    align-items: center;
    display: flex;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
}

.Element__min {
  align-items: flex-start;
  border-bottom: 1px solid #dfe6e9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;

  & > div {
    flex: 0 0 20%;
    word-break: break-word;

    &:first-of-type {
      flex: 1;
    }

    &:last-of-type {
      flex: 0 0 auto;
    }
  }
}

.Element {
  border-bottom: 1px solid #dfe6e9;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;

  .main {
    align-items: start;
    column-gap: 16px;
    display: grid;
    grid-template-columns: 96px 200px 1fr 1fr 1fr;
    padding: 24px 0 16px;
    word-break: break-word;
  }

  .footer {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
  }
}

@include for-tablet-portrait-up {
  .Element__min {
    flex-direction: row;
    gap: 0;
  }
}
